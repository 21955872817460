import NucleusComponentBase from "@/shared/application/nucleus-component-base";
import { Component } from "vue-property-decorator";
import AppConsts from "@/shared/application/nucleus";
@Component
export default class TrendyolInfoComponent extends NucleusComponentBase {
  // TODO: Add example components
  downloadTrendyolPdf(id: any) {
    var downloadPath = "/api/definitions/DownloadTrendyolPdf";
    const link = document.createElement("a");
    link.href = AppConsts.baseApiUrl + downloadPath;
    link.click();
    URL.revokeObjectURL(link.href);
    this.swalToast(2000, "success", this.$t("Successful").toString());
  }
  downloadTaxPdf(id: any) {
    var downloadPath = "/api/definitions/DownloadTaxPdf";
    const link = document.createElement("a");
    link.href = AppConsts.baseApiUrl + downloadPath;
    link.click();
    URL.revokeObjectURL(link.href);
    this.swalToast(2000, "success", this.$t("Successful").toString());
  }
}
