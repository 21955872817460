import NucleusComponentBase from "@/shared/application/nucleus-component-base";
import { Component, Watch } from "vue-property-decorator";
import Guid from "@/shared/helpers/guid-helper";
import { stringify } from "query-string";

@Component
export default class StorageListComponent extends NucleusComponentBase {
  refs = this.$refs as any;
  allPermissions: IPermissionDto[] = [];
  errors: INameValueDto[] = [];
  loading = true;
  dialog = false;
  formTitle = "";
  options = {};
  search = "";
  selectAll = false;

  get headers() {
    return [
      { text: this.$t("Key"), value: "key" },
      { text: this.$t("Value"), value: "value" },
      { text: this.$t("Actions"), value: "action", sortable: false },
    ];
  }

  createOrUpdateStorageInput = {
    storage: {} as any,
  } as any;

  pagedListOfStorageListDto: [] = [];

  @Watch("options")
  onPaginationChanged() {
    this.getStorages();
  }

  @Watch("search")
  onSearchChanged() {
    this.getStorages();
  }

  mounted() {
    this.getStorages();
  }

  editStorage(id: string) {
    debugger;
    this.formTitle = id
      ? this.$t("EditStorage").toString()
      : this.$t("NewStorage").toString();
    this.errors = [];
    this.nucleusService
      .get<any>(
        "/api/storage/" + (id ? id : Guid.empty)
      )
      .then((response) => {
        const result = response.content as any;
        this.createOrUpdateStorageInput = {
          storage: result,
        };
        this.dialog = true;
      });
  }

  deleteStorage(id: string) {
    this.swalConfirm(this.$t("AreYouSureToDelete").toString()).then(
      (result) => {
        if (result.value) {
          this.nucleusService
            .delete("/api/storage?id=" + id)
            .then((response) => {
              if (!response.isError) {
                this.swalToast(
                  2000,
                  "success",
                  this.$t("Successful").toString()
                );
                this.getStorages();
              } else {
                var errorText = "";
                response.errors.forEach((error) => {
                  errorText += this.$t(error.name) + "<br>";
                });

                this.swalAlert("error", errorText);
              }
            });
        }
      }
    );
  }

  save() {
    console.log(this.createOrUpdateStorageInput.storage);
    if (this.refs.form.validate()) {
      this.errors = [];
      this.nucleusService
        .postOrPut<void>(
          "/api/storage/"+this.createOrUpdateStorageInput.storage.id,
          this.createOrUpdateStorageInput.storage as any,"0"
        )
        .then((response) => {
          if (!response.isError) {
            this.swalToast(2000, "success", this.$t("Successful").toString());
            this.dialog = false;
            this.getStorages();
          } else {
            this.errors = response.errors;
          }
        });
    }
  }

  getStorages() {
    this.loading = true;
  

    
    this.nucleusService
      .get<any>("/api/storage/GetStorages" , false)
      .then((response) => {
        this.pagedListOfStorageListDto = response.content as [];

        console.log(this.pagedListOfStorageListDto);
        this.loading = false;
      });
  }
}
