import "./assets/sass/site.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueI18n from "vue-i18n";
import LanguageStore from "@/stores/language-store";
import Multiselect from "vue-multiselect";
import moment from "moment";

import { VueMaskDirective } from "v-mask";
import Clipboard from "v-clipboard";
import VueTrix from "vue-trix";

Vue.use(VueTrix);

Vue.use(Clipboard);
Vue.directive("mask", VueMaskDirective);

Vue.component("multiselect", Multiselect);

Vue.use(VueI18n);

const locales = {
  en: require("@/assets/localizations/en.json"),
  tr: require("@/assets/localizations/tr.json"),
};

const i18n = new VueI18n({
  locale: LanguageStore.getLanguage().languageCode,
  fallbackLocale: "en",
  messages: locales,
});

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

Vue.filter("format2Digit", (value: number) => {
  if (value) {
    return (Math.round(value * 100) / 100).toFixed(2);
  }
});
Vue.filter("formatDate", (value: string) => {
  if (value) {
    return moment(value).format("DD/MM/YYYY HH:mm ");
  }
});
