import NucleusComponentBase from "@/shared/application/nucleus-component-base";
import Swal from "sweetalert2";
import { Component } from "vue-property-decorator";

@Component
export default class HomeComponent extends NucleusComponentBase {
  // TODO: Add example components


  openTrendyolPopup() {
    let routeData = this.$router.resolve({
      path: "/admin/trendyol-info",
    });
    window.location.href = routeData.href;
  }

  openFantaslinkPopUp() {
    let routeData = this.$router.resolve({
      path: "/admin/fantaslink-info",
    });
    window.location.href = routeData.href;
  }
  get mainMenuItems() {
    return [
      { icon: "mdi-account", text: this.$t("Profile"), link: "/admin/profile" },
      // {
      //   icon: "mdi-link",
      //   text: this.$t("Link Oluştur"),
      //   link: "/admin/link-creator",
      // },
      {
        icon: "mdi-gavel",
        text: "Yeni Kanun Hesap Bilgisi",
        link: "/admin/legislation",
      },
      {
        icon: "mdi-currency-usd",
        text: this.$t("PaymentTracking"),
        link: "/admin/payment-tracking-list",
      },
      // {
      //   icon: "mdi-ticket",
      //   text: this.$t("Ticket"),
      //   link: "/admin/ticket-list",
      // },

      {
        icon: "mdi-share-variant-outline",
        text: "Kampanya Raporu",
        link: "/admin/campaign",
      },

      { icon: "mdi-domain", text: this.$t("Kurumsal"), link: "/admin/info" },
      {
        icon: "mdi-chat-question",
        text: "Sık Sorulan Sorular",
        link: "/admin/faq",
      },
    ];
  }
}
