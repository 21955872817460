import NucleusComponentBase from "@/shared/application/nucleus-component-base";
import { Component, Watch } from "vue-property-decorator";
import Guid from "@/shared/helpers/guid-helper";
import { stringify } from "query-string";
import { debug } from "console";

@Component
export default class TrendyolCommissionListComponent extends NucleusComponentBase {
  refs = this.$refs as any;
  allPermissions: IPermissionDto[] = [];
  errors: INameValueDto[] = [];
  loading = true;
  dialog = false;
  formTitle = "";
  options = {};
  search = "";
  selectAll = false;
  files = [];

  get headers() {
    return [
      { text: this.$t("TrendyolUserName"), value: "userName" },
      { text: this.$t("InvoiceId"), value: "invoiceId" },
      { text: this.$t("TotalAmount"), value: "total" },
      { text: this.$t("IsHaveUserInCms"), value: "isHaveUserInCms" },
    ];
  }

  createOrUpdateTrendyolCommissionInput = {
    trendyolCommission: {} as ITrendyolCommissionDto,
  } as ICreateOrUpdateTrendyolCommissionInput;

  pagedListOfTrendyolCommissionListDto: IPagedList<
    ITrendyolCommissionListOutput
  > = {
    totalCount: 0,
    items: [],
  };

  @Watch("options")
  onPaginationChanged() {
    this.getTrendyolCommissions();
  }

  @Watch("search")
  onSearchChanged() {
    this.getTrendyolCommissions();
  }

  mounted() {
    this.getTrendyolCommissions();
  }

  editTrendyolCommission(id: string) {
    this.formTitle = id
      ? this.$t("EditTrendyolCommission").toString()
      : this.$t("NewTrendyolCommission").toString();
    this.errors = [];
    this.nucleusService
      .get<IGetTrendyolCommissionForCreateOrUpdateOutput>(
        "/api/trendyolCommissions/" + (id ? id : Guid.empty)
      )
      .then((response) => {
        const result = response.content as IGetTrendyolCommissionForCreateOrUpdateOutput;
        this.createOrUpdateTrendyolCommissionInput = {
          trendyolCommission: result.trendyolCommission,
        };
        this.dialog = true;
      });
  }

  deleteTrendyolCommission(id: string) {
    this.swalConfirm(this.$t("AreYouSureToDelete").toString()).then(
      (result) => {
        if (result.value) {
          this.nucleusService
            .delete("/api/trendyolCommissions?id=" + id)
            .then((response) => {
              if (!response.isError) {
                this.swalToast(
                  2000,
                  "success",
                  this.$t("Successful").toString()
                );
                this.getTrendyolCommissions();
              } else {
                var errorText = "";
                response.errors.forEach((error) => {
                  errorText += this.$t(error.name) + "<br>";
                });

                this.swalAlert("error", errorText);
              }
            });
        }
      }
    );
  }

  save() {
    if (this.refs.form.validate()) {
      this.errors = [];
      this.nucleusService
        .postOrPut<void>(
          "/api/trendyolCommissions",
          this
            .createOrUpdateTrendyolCommissionInput as ICreateOrUpdateTrendyolCommissionInput,
          this.createOrUpdateTrendyolCommissionInput.trendyolCommission.id
        )
        .then((response) => {
          if (!response.isError) {
            this.swalToast(2000, "success", this.$t("Successful").toString());
            this.dialog = false;
            this.getTrendyolCommissions();
          } else {
            this.errors = response.errors;
          }
        });
    }
  }

  getTrendyolCommissions() {
    this.loading = true;
    const { sortBy, sortDesc, page, itemsPerPage }: any = this.options;
    const trendyolCommissionListInput: IPagedListInput | any = {
      filter: this.search,
      pageIndex: page - 1,
      pageSize: itemsPerPage,
    };

    if (sortBy.length > 0 && sortBy[0]) {
      trendyolCommissionListInput.sortBy =
        sortBy + (sortDesc.length > 0 && sortDesc[0] ? " desc" : "");
    }

    const query = "?" + this.queryString.stringify(trendyolCommissionListInput);
    this.nucleusService
      .get<IPagedList<ITrendyolCommissionListOutput>>(
        "/api/trendyolCommissions" + query,
        false
      )
      .then((response) => {
        this.pagedListOfTrendyolCommissionListDto = response.content as IPagedList<
          ITrendyolCommissionListOutput
        >;
        this.loading = false;
      });
  }
  openFileUploadDialog() {
    var uploaderInput = this.$refs.uploader as HTMLElement;
    uploaderInput.click();
  }
  onFileChanged(e: any) {
    debugger;
    var files = e.target.files[0];
    if (this.files != undefined) {
      this.errors = [];

      var formData = new FormData();
      formData.append("files", files);
      this.nucleusService
        .postExcel<void>("/api/trendyolCommissions/UploadWithExcel", formData)
        .then((response) => {
          if (!response.isError) {
            this.swalToast(2000, "success", this.$t("Successful").toString());
            this.dialog = false;
            this.getTrendyolCommissions();
          } else {
            this.errors = response.errors;
          }
        });
    }
  }
}
