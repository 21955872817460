import NucleusComponentBase from "@/shared/application/nucleus-component-base";
import { Component } from "vue-property-decorator";
import Swal from "sweetalert2";
@Component
export default class AsideMenuComponent extends NucleusComponentBase {
  drawer = false;
  isAdmin = false;

  get mainMenuItems() {
    return [
      { icon: "mdi-home", text: this.$t("Home"), link: "/admin/home" },
      // { icon: "mdi-link", text: this.$t("Link Oluştur"), link: "/admin/link-creator" },
      { icon: "mdi-account", text: this.$t("Profile"), link: "/admin/profile" },
      {
        icon: "mdi-gavel",
        text: "Yeni Kanun Hesap Bilgisi",
        link: "/admin/legislation",
      },
      {
        icon: "mdi-share-variant-outline",
        text: "Kampanya Raporu",
        link: "/admin/campaign",
      },
      {
        icon: "mdi-share-variant-outline",
        text: "Kuponlar",
        link: "/admin/coupon",
      },

      {
        icon: "mdi-share-variant-outline",
        text: "Koleksiyon Listesi",
        link: "/admin/collection-list",
      },
      {
        icon: "mdi-currency-usd",
        text: this.$t("PaymentTracking"),
        link: "/admin/payment-tracking-list",
      },
      {
        icon: "mdi-ticket",
        text: this.$t("Ticket"),
        link: "/admin/ticket-list",
      },

      { icon: "mdi-domain", text: this.$t("Kurumsal"), link: "/admin/info" },
      {
        icon: "mdi-chat-question",
        text: "Sık Sorulan Sorular",
        link: "/admin/faq",
      },
    ];
  }

  get adminMenuItems() {
    return [
      {
        icon: "mdi-account-multiple",
        text: this.$t("Users"),
        link: "/admin/user-list",
      },
      {
        icon: "mdi-briefcase-account",
        text: this.$t("Roles"),
        link: "/admin/role-list",
      },
      {
        icon: "mdi-home-silo",
        text: "Markalar",
        link: "/admin/company-list",
      },
      {
        icon: "mdi-brightness-percent",
        text: "Avantajlı ürünler",
        link: "/admin/discount-campaign-list",
      },
      {
        icon: "mdi-credit-card-refresh",
        text: "Komisyonlar",
        link: "/admin/commission-list",
      },
      {
        icon: "mdi-briefcase-account",
        text: this.$t("TrendyolCommission"),
        link: "/admin/trendyol-commission-list",
      },
      {
        icon: "mdi-brightness-7",
        text: "Ayarlar",
        link: "/admin/storage-list",
      },
      {
        icon: "mdi-user",
        text: "Marka Kullanıcılar",
        link: "/admin/company-user",
      },
    ];
  }

  mounted() {
    this.$root.$on("drawerChanged", () => {
      this.drawer = !this.drawer;
    });
  }

  saveNextPaymentDate() {
    Swal.fire({
      title: "Gelecek Ödeme Tarihi",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Kaydet",
      showLoaderOnConfirm: true,
      preConfirm: (login) => {
        var dateTimeInput = {
          dateTime: login,
        };
        this.nucleusService
          .post<boolean>("/api/definitions", dateTimeInput)
          .then((response) => {
            if (!response) {
              this.swalToast(2000, "success", this.$t("Successful").toString());
            }
          });
      },
    });
  }
}
