export default class LanguageStore {
  public static storageKey: string = "language";

  public static getLanguage(): ILanguageDto {
    return { languageCode: "tr", languageName: "Türkçe" } as ILanguageDto;
  }

  public static setLanguage(input: ILanguageDto) {
    localStorage.setItem(LanguageStore.storageKey, JSON.stringify(input));
  }

  public static removeLanguage(): void {
    localStorage.removeItem(LanguageStore.storageKey);
  }
}
