import { Component } from "vue-property-decorator";
import NucleusComponentBase from "@/shared/application/nucleus-component-base";
import Swal from "sweetalert2";
@Component
export default class LoginComponent extends NucleusComponentBase {
  public refs = this.$refs as any;
  public loginInput = {} as ILoginInput;
  public errors: INameValueDto[] = [];

  showEmailSendAgainButton = false;
  public onSubmit() {
    if (this.refs.form.validate()) {
      this.nucleusService
        .post<ILoginOutput>("/api/login", this.loginInput)
        .then((response) => {
          if (!response.isError) {
            this.authStore.setToken(response.content.token);
            this.$router.push({ path: "/admin/home" });
          } else {
            this.errors = response.errors;

            if (this.errors.find((x) => x.value == "EmailNotConfirm") != null) {
              this.showEmailSendAgainButton = true;
            }
          }
        });
    }
  }
  public openSendConfirmEmailPopup() {
    Swal.fire({
      title: "Doğrulama maili göndermek için lütfen email adresinizi giriniz",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Gönder",
      cancelButtonText: "İptal",
      showLoaderOnConfirm: true,
      preConfirm: (email) => {
        var confirmInput = {
          email: email,
          clientDomain: "https://" + window.location.hostname,
        };
        this.nucleusService
          .post<IRestResponseDto<boolean>>(
            "/api/SendEmailConfirmation",
            confirmInput
          )
          .then((response) => {
            if (!response.isError) {
              this.swalToast(2000, "success", this.$t("Successful").toString());
            } else {
              this.swalToast(
                5000,
                "error",
                "Kullanıcı bulunamadı. Lütfen tekrar deneyin"
              );
            }
          });
      },
    });
  }
}
