import NucleusComponentBase from "@/shared/application/nucleus-component-base";
import { Component, Watch } from "vue-property-decorator";
import Guid from "@/shared/helpers/guid-helper";
import { stringify } from "query-string";
var builder = require("utm-builder");
import Swal from "sweetalert2";

@Component
export default class CollectionListComponent extends NucleusComponentBase {
  refs = this.$refs as any;
  allPermissions: IPermissionDto[] = [];
  errors: INameValueDto[] = [];
  loading = true;
  dialog = false;
  formTitle = "";
  options = {};
  search = "";
  selectAll = false;

  username = "";
  linkSource = {
    link: "",
    campaign: "",
    source: "",
    medium: "",
  };

  get headers() {
    return [
      { text: this.$t("Koleksiyon Adı"), value: "name" },
      { text: this.$t("Actions"), value: "action", sortable: false },
    ];
  }
  get headersCollectionItem() {
    return [
      { text: this.$t(""), value: "order" },
      { text: this.$t("Link "), value: "url" },
      { text: this.$t("Actions"), value: "action", sortable: false },
    ];
  }

  createOrUpdateCollectionInput = {
    collectionItems: [],
    collection: {} as ICollectionDto,
  } as ICreateOrUpdateCollectionInput;

  pagedListOfCollectionListDto: IPagedList<ICollectionListOutput> = {
    totalCount: 0,
    items: [],
  };

  collectionItemUrl = "";

  collectionUserName = "";

  mounted() {
    this.collectionUserName = window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];

    this.getCollections();
  }

  getCollections() {
    this.loading = true;

    this.nucleusService
      .get<IPagedList<ICollectionListOutput>>(
        "/api/collections/getCollectionsWithUserName/" +
          this.collectionUserName,
        false
      )
      .then((response) => {
        this.pagedListOfCollectionListDto = response.content as IPagedList<
          ICollectionListOutput
        >;
        this.loading = false;
      });
  }
  addCollectionItem() {
    this.createLink(this.collectionItemUrl);

    this.collectionItemUrl = "";
  }
  deleteCollectionItem(item: any) {
    this.createOrUpdateCollectionInput.collectionItems.splice(
      this.createOrUpdateCollectionInput.collectionItems.indexOf(item),
      1
    );
  }

  getUserName() {
    var id = this.authStore.getTokenData()[
      "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
    ];
    console.log(id);
    this.nucleusService
      .get<IGetUserForCreateOrUpdateOutput>(
        "/api/profile/GetUserNameWithId?id=" + id
      )
      .then((response) => {
        const result = response.content as IGetUserForCreateOrUpdateOutput;
        this.username = result.user.userName;

        this.linkSource.source = "influencer_" + this.username.toLowerCase();
      });
  }

  createLink(link: any) {
    var message = "";

    var utmLink = builder(
      link,
      "influencer_" + this.username.toLowerCase(),
      "affiliate",
      "tatil"
    );
    console.log(utmLink);
    var encodedLink = encodeURIComponent(utmLink);

    this.createOrUpdateCollectionInput.collectionItems.push({
      url: utmLink,
      order: this.createOrUpdateCollectionInput.collectionItems.length + 1,
    });
  }
  gotoPage(page: any) {
    console.log(page);
    window.location.href = this.collectionUserName + "/" + page;
  }
}
