import NucleusComponentBase from "@/shared/application/nucleus-component-base";
import { Component, Watch } from "vue-property-decorator";
import Guid from "@/shared/helpers/guid-helper";
import { stringify } from "query-string";

import { VueEditor } from "vue2-editor";

@Component({
  components: {
    VueEditor,
  },
})
export default class CompanyListComponent extends NucleusComponentBase {
  refs = this.$refs as any;
  allPermissions: IPermissionDto[] = [];
  errors: INameValueDto[] = [];
  loading = true;
  dialog = false;
  formTitle = "";
  options = {};
  search = "";
  selectAll = false;

  get headers() {
    return [
      { text: this.$t("CompanyName"), value: "name" },
      { text: this.$t("Actions"), value: "action", sortable: false },
    ];
  }

  createOrUpdateCompanyInput = {
    company: {} as ICompanyDto,
  } as ICreateOrUpdateCompanyInput;

  pagedListOfCompanyListDto: IPagedList<ICompanyListOutput> = {
    totalCount: 0,
    items: [],
  };

  @Watch("options")
  onPaginationChanged() {
    this.getCompanies();
  }

  @Watch("search")
  onSearchChanged() {
    this.getCompanies();
  }

  mounted() {
    this.getCompanies();
  }

  editCompany(id: string) {
    this.formTitle = id
      ? this.$t("EditCompany").toString()
      : this.$t("NewCompany").toString();
    this.errors = [];
    this.nucleusService
      .get<IGetCompanyForCreateOrUpdateOutput>(
        "/api/companies/" + (id ? id : Guid.empty)
      )
      .then((response) => {
        const result = response.content as IGetCompanyForCreateOrUpdateOutput;
        this.createOrUpdateCompanyInput = {
          company: result.company,
        };
        this.dialog = true;
      });
  }

  deleteCompany(id: string) {
    this.swalConfirm(this.$t("AreYouSureToDelete").toString()).then(
      (result) => {
        if (result.value) {
          this.nucleusService
            .delete("/api/companies?id=" + id)
            .then((response) => {
              if (!response.isError) {
                this.swalToast(
                  2000,
                  "success",
                  this.$t("Successful").toString()
                );
                this.getCompanies();
              } else {
                var errorText = "";
                response.errors.forEach((error) => {
                  errorText += this.$t(error.name) + "<br>";
                });

                this.swalAlert("error", errorText);
              }
            });
        }
      }
    );
  }

  save() {
    if (this.refs.form.validate()) {
      this.errors = [];
      this.nucleusService
        .postOrPut<void>(
          "/api/companies",
          this.createOrUpdateCompanyInput as ICreateOrUpdateCompanyInput,
          this.createOrUpdateCompanyInput.company.id
        )
        .then((response) => {
          if (!response.isError) {
            this.swalToast(2000, "success", this.$t("Successful").toString());
            this.dialog = false;
            this.getCompanies();
          } else {
            this.errors = response.errors;
          }
        });
    }
  }

  getCompanies() {
    this.loading = true;
    const { sortBy, sortDesc, page, itemsPerPage }: any = this.options;
    const companyListInput: IPagedListInput | any = {
      filter: this.search,
      pageIndex: page - 1,
      pageSize: itemsPerPage,
    };

    if (sortBy.length > 0 && sortBy[0]) {
      companyListInput.sortBy =
        sortBy + (sortDesc.length > 0 && sortDesc[0] ? " desc" : "");
    }

    const query = "?" + this.queryString.stringify(companyListInput);
    this.nucleusService
      .get<IPagedList<ICompanyListOutput>>("/api/companies" + query, false)
      .then((response) => {
        this.pagedListOfCompanyListDto = response.content as IPagedList<
          ICompanyListOutput
        >;
        this.loading = false;
      });
  }
}
