import NucleusComponentBase from "@/shared/application/nucleus-component-base";
import { Component, Watch } from "vue-property-decorator";
import Guid from "@/shared/helpers/guid-helper";
import { stringify } from "query-string";
import moment from "moment";
@Component
export default class TicketListComponent extends NucleusComponentBase {
  refs = this.$refs as any;
  allPermissions: IPermissionDto[] = [];
  errors: INameValueDto[] = [];

  loading = true;
  dialog = false;
  formTitle = "";
  options = {};
  search = "";
  selectAll = false;
  userId = "";
  users: IPagedList<IUserListOutput> = {
    totalCount: 0,
    items: [],
  };
  admins = [];
  selectedAdmin = {};
  getOnlyAssignedToMe = false;
  getOnlyArchived = false;
  selectedStatusFilter = "";
  dataLoaded = false;

  companies = [] as ICompanyListOutput[];
  selectedCompanyFilter = "00000000-0000-0000-0000-000000000000";
  get headers() {
    return [
      { text: this.$t("TicketSubject"), value: "subject" },
      { text: "Güncelleme Tarihi", value: "modificationDate" },
      { text: this.$t("Status"), value: "status" },
      { text: "Marka", value: "companyName" },
      { text: this.$t("Yazışmalar"), value: "action", sortable: false },
    ];
  }
  get adminHeaders() {
    return [
      { text: "Kullanıcı Adı", value: "fullName" },
      { text: this.$t("TicketSubject"), value: "subject" },
      { text: "Güncelleme Tarihi", value: "modificationDate" },
      { text: "Gün", value: "daydiff" },
      { text: this.$t("Status"), value: "status" },
      { text: "Marka", value: "companyName" },
      { text: this.$t("Yazışmalar"), value: "action", sortable: false },
    ];
  }

  get status() {
    return [
      { text: "Gönderildi", value: "1" },
      { text: this.$t("Closed"), value: "2" },
      { text: "Cevap bekliyor", value: "3" },
    ];
  }
  get adminStatus() {
    return [
      { text: this.$t("Open"), value: "1" },
      { text: this.$t("Closed"), value: "2" },
      { text: "Gönderildi", value: "3" },
    ];
  }
  get adminFilterStatus() {
    return [
      { text: "Cevap Bekliyor", value: "1" },
      { text: "Destek Talebi Kapatıldı", value: "2" },
      { text: "Gönderildi", value: "3" },
    ];
  }

  get subjects() {
    return [
      { text: "Lütfen Konu Seçiniz", value: 0 },
      { text: "Üyelik", value: 1 },
      { text: "Ödeme", value: 2 },
      { text: "Diğer", value: 3 },
      { text: "Duyuru", value: 4 },
    ];
  }
  get memberSubjects() {
    return [
      { text: "Lütfen Konu Seçiniz", value: 0 },
      { text: "Üyelik", value: 1 },
      { text: "Ödeme", value: 2 },
      { text: "Diğer", value: 3 },
    ];
  }
  createOrUpdateTicketInput = {
    chats: [],
    selectedUsers: [],
    sendAllUser: false,
    ticket: {} as ITicketDto,
  } as ICreateOrUpdateTicketInput;

  pagedListOfTicketListDto: IPagedList<ITicketListOutput> = {
    totalCount: 0,
    items: [],
  };

  @Watch("options")
  onPaginationChanged() {
    this.getTickets();
  }

  @Watch("search")
  onSearchChanged() {
    this.getTickets();
  }
  @Watch("selectedCompanyFilter")
  onCompanyIdChanged() {
    this.getTickets();
  }

  mounted() {
    this.getTickets();
  }
  @Watch("selectedStatusFilter")
  onSelectedStatusChanged() {
    this.getTickets();
  }

  editTicket(id: string) {
    this.userId = this.authStore.getTokenData()[
      "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
    ];

    this.formTitle = id
      ? this.$t("EditTicket").toString()
      : this.$t("NewTicket").toString();
    this.errors = [];
    this.nucleusService
      .get<IGetTicketForCreateOrUpdateOutput>(
        "/api/tickets/" + (id ? id : Guid.empty)
      )
      .then((response) => {
        const result = response.content as IGetTicketForCreateOrUpdateOutput;

        this.createOrUpdateTicketInput = {
          chats: result.chats,
          ticket: result.ticket,
          sendAllUser: false,
          selectedUsers: [],
        };
        this.admins = result.admins;

        this.selectedAdmin = result.admins.filter(
          (x: any) =>
            this.createOrUpdateTicketInput.ticket.assignedUserId == x.id
        );
        this.dialog = true;
      });
  }

  deleteTicket(id: string) {
    this.swalConfirm("Destek Talebini Arşivlemek İstiyor Musunuz?").then(
      (result) => {
        if (result.value) {
          this.nucleusService
            .delete("/api/tickets?id=" + id)
            .then((response) => {
              if (!response.isError) {
                this.swalToast(
                  2000,
                  "success",
                  this.$t("Successful").toString()
                );
                this.getTickets();
              } else {
                var errorText = "";
                response.errors.forEach((error) => {
                  errorText += this.$t(error.name) + "<br>";
                });

                this.swalAlert("error", errorText);
              }
            });
        }
      }
    );
  }

  save() {
    debugger;
    if (this.refs.form.validate()) {
      this.errors = [];
      this.nucleusService
        .postOrPut<void>(
          "/api/tickets",
          this.createOrUpdateTicketInput as ICreateOrUpdateTicketInput,
          this.createOrUpdateTicketInput.ticket.id
        )
        .then((response) => {
          if (!response.isError) {
            this.swalToast(2000, "success", this.$t("Successful").toString());
            this.dialog = false;
            if (this.createOrUpdateTicketInput.ticket.id != Guid.empty) {
              var currentTicketId = this.createOrUpdateTicketInput.ticket.id;
              this.editTicket(currentTicketId ? currentTicketId : "");
            } else {
              this.getTickets();
            }

            var hr = new Date().getHours();
            if (hr < 8 || hr > 17) {
              this.swalAlert(
                "warning",
                "Mesai saatleri içerisinde cevap verilecektir"
              );
            }
          } else {
            this.errors = response.errors;
          }
        });
    }
  }
  assignAdminForTicket(selectedOption: any, id: any) {
    this.createOrUpdateTicketInput.ticket.assignedUserId = selectedOption.id.toString();
  }
  removeAssignedAdmin(selectedOption: any, id: any) {
    this.createOrUpdateTicketInput.ticket.assignedUserId = Guid.empty;
  }

  getTickets() {
    this.$root.$emit("updateNotification");
    this.getCompanies();
    this.loading = true;
    const { sortBy, sortDesc, page, itemsPerPage }: any = this.options;
    const ticketListInput: IPagedListInput | any = {
      filter: this.search,
      pageIndex: page - 1,
      pageSize: itemsPerPage,
      getOnlyAssignedToMe: this.getOnlyAssignedToMe,
      getOnlyArchived: this.getOnlyArchived,
      statusFilter: this.selectedStatusFilter,
      companyId: this.selectedCompanyFilter,
    };

    if (sortBy.length > 0 && sortBy[0]) {
      ticketListInput.sortBy =
        sortBy + (sortDesc.length > 0 && sortDesc[0] ? " desc" : "");
    }

    const query = "?" + this.queryString.stringify(ticketListInput);
    this.nucleusService
      .get<IPagedList<ITicketListOutput>>("/api/tickets" + query, false)
      .then((response) => {
        this.pagedListOfTicketListDto = response.content as IPagedList<
          ITicketListOutput
        >;
        this.loading = false;
      });
  }
  scrollbottom() {
    this.$nextTick(function() {
      var chatDiv = this.$refs.chatDiv as HTMLElement;
      chatDiv.scrollTop = chatDiv.scrollHeight + 120;
    });
  }

  getUsers(search: any) {
    this.loading = true;
    const { sortBy, sortDesc, page, itemsPerPage }: any = this.options;
    const userListInput: IPagedListInput | any = {
      filter: search,
      pageIndex: page - 1,
      pageSize: itemsPerPage,
    };

    if (sortBy.length > 0 && sortBy[0]) {
      userListInput.sortBy =
        sortBy + (sortDesc.length > 0 && sortDesc[0] ? " desc" : "");
    }

    const query = "?" + this.queryString.stringify(userListInput);
    this.nucleusService
      .get<IPagedList<IPagedListInput>>("/api/users" + query, false)
      .then((response) => {
        this.users = response.content as IPagedList<IUserListOutput>;
        this.loading = false;
      });
  }
  clearAll() {
    this.createOrUpdateTicketInput.selectedUsers = [];
  }
  limitText() {
    return (
      "ve " +
      (this.createOrUpdateTicketInput.selectedUsers.length - 3) +
      " başka kullanıcı seçildi"
    );
  }
  closeChatRefreshTickets() {
    this.dialog = false;
    this.getTickets();
  }
  showProfile() {
    let routeData = this.$router.resolve({
      path: "/admin/profile",
      query: { id: this.createOrUpdateTicketInput.ticket.userId },
    });
    window.open(routeData.href, "_blank");
  }
  getDay(date: any) {
    return moment(new Date()).diff(moment(date), "days");
  }
  openFileUploadDialog() {
    var uploaderInput = this.$refs.uploaderLegislation as HTMLElement;
    uploaderInput.click();
  }
  onFileChanged(e: any) {
    debugger;
    var files = e.target.files[0];
    var ticket = this.createOrUpdateTicketInput.ticket;

    if (files != undefined) {
      this.checkSize(10485760, files);

      this.errors = [];

      var formData = new FormData();
      formData.append("files", files);
      formData.append("ticket", JSON.stringify(ticket));
      this.nucleusService
        .postExcel<void>("/api/tickets/AddPhotoToChat", formData)
        .then((response) => {
          if (!response.isError) {
            this.swalToast(2000, "success", this.$t("Successful").toString());
            this.dialog = false;
          } else {
            this.errors = response.errors;
          }
        });
    }
  }
  checkSize(max_size: any, file: any) {
    debugger;

    if (file.size > max_size) {
      this.swalToastCenter(
        3000,
        "error",
        "Seçtiğiniz dosya en fazla " +
          max_size / 1024 / 1024 +
          "MB olabilir. Lütfen farklı bir dosya seçiniz"
      );
      return false;
    }

    return true;
  }
  getCompanies() {
    this.loading = true;
    const { sortBy, sortDesc, page, itemsPerPage }: any = this.options;
    const companyListInput: IPagedListInput | any = {
      filter: this.search,
      pageIndex: page - 1,
      pageSize: itemsPerPage,
    };

    if (sortBy.length > 0 && sortBy[0]) {
      companyListInput.sortBy =
        sortBy + (sortDesc.length > 0 && sortDesc[0] ? " desc" : "");
    }

    const query = "?" + this.queryString.stringify(companyListInput);
    this.nucleusService
      .get<IPagedList<ICompanyListOutput>>("/api/companies" + query, false)
      .then((response) => {
        var companyResponse = response.content as IPagedList<
          ICompanyListOutput
        >;
        this.companies = companyResponse.items;
        this.loading = false;
      });
  }
}
