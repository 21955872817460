import NucleusComponentBase from "@/shared/application/nucleus-component-base";
import { Component, Watch } from "vue-property-decorator";
import Guid from "@/shared/helpers/guid-helper";
import { stringify } from "query-string";

@Component
export default class DiscountCampaignListComponent extends NucleusComponentBase {
  refs = this.$refs as any;
  allPermissions: IPermissionDto[] = [];
  errors: INameValueDto[] = [];
  loading = true;
  dialog = false;
  formTitle = "";
  options = {};
  search = "";
  selectAll = false;
  companies = [] as ICompanyListOutput[];
  selectedCompanyFilter = "00000000-0000-0000-0000-000000000000";
  get headers() {
    return [
      { text: "Adi", value: "name" },
      { text: "Aciklama", value: "description" },
      { text: "Url", value: "url" },
      { text: "Mobil Link", value: "isMobileLink" },
      { text: "Marka", value: "companyName" },
      { text: this.$t("Actions"), value: "action", sortable: false },
    ];
  }

  createOrUpdateDiscountCampaignInput = {
    discountCampaign: {} as IDiscountCampaignDto,
  } as ICreateOrUpdateDiscountCampaignInput;

  pagedListOfDiscountCampaignListDto: IPagedList<
    IDiscountCampaignListOutput
  > = {
    totalCount: 0,
    items: [],
  };

  @Watch("options")
  onPaginationChanged() {
    this.getDiscountCampaigns();
  }

  @Watch("search")
  onSearchChanged() {
    this.getDiscountCampaigns();
  }

  @Watch("selectedCompanyFilter")
  onCompanyIdChanged() {
    this.getDiscountCampaigns();
  }
  mounted() {
    this.getDiscountCampaigns();
  }

  editDiscountCampaign(id: string) {
    this.formTitle = id ? "Duzenle" : "Yeni Avantajlı Urun Ekle";
    this.errors = [];
    this.nucleusService
      .get<IGetDiscountCampaignForCreateOrUpdateOutput>(
        "/api/DiscountCampaigns/" + (id ? id : Guid.empty)
      )
      .then((response) => {
        const result = response.content as IGetDiscountCampaignForCreateOrUpdateOutput;
        this.createOrUpdateDiscountCampaignInput = {
          discountCampaign: result.discountCampaign,
        };
        this.dialog = true;
      });
  }

  deleteDiscountCampaign(id: string) {
    this.swalConfirm(this.$t("AreYouSureToDelete").toString()).then(
      (result) => {
        if (result.value) {
          this.nucleusService
            .delete("/api/discountCampaigns?id=" + id)
            .then((response) => {
              if (!response.isError) {
                this.swalToast(
                  2000,
                  "success",
                  this.$t("Successful").toString()
                );
                this.getDiscountCampaigns();
              } else {
                var errorText = "";
                response.errors.forEach((error) => {
                  errorText += this.$t(error.name) + "<br>";
                });

                this.swalAlert("error", errorText);
              }
            });
        }
      }
    );
  }

  save() {
    if (this.refs.form.validate()) {
      this.errors = [];
      this.nucleusService
        .postOrPut<void>(
          "/api/DiscountCampaigns",
          this
            .createOrUpdateDiscountCampaignInput as ICreateOrUpdateDiscountCampaignInput,
          this.createOrUpdateDiscountCampaignInput.discountCampaign.id
        )
        .then((response) => {
          if (!response.isError) {
            this.swalToast(2000, "success", this.$t("Successful").toString());
            this.dialog = false;
            this.getDiscountCampaigns();
          } else {
            this.errors = response.errors;
          }
        });
    }
  }

  getDiscountCampaigns() {
    this.getCompanies();
    this.loading = true;
    const { sortBy, sortDesc, page, itemsPerPage }: any = this.options;
    const discountCampaignListInput: IPagedListInput | any = {
      filter: this.search,
      pageIndex: page - 1,
      pageSize: itemsPerPage,
      companyId: this.selectedCompanyFilter,
    };

    if (sortBy.length > 0 && sortBy[0]) {
      discountCampaignListInput.sortBy =
        sortBy + (sortDesc.length > 0 && sortDesc[0] ? " desc" : "");
    }

    const query = "?" + this.queryString.stringify(discountCampaignListInput);
    this.nucleusService
      .get<IPagedList<IDiscountCampaignListOutput>>(
        "/api/DiscountCampaigns" + query,
        false
      )
      .then((response) => {
        this.pagedListOfDiscountCampaignListDto = response.content as IPagedList<
          IDiscountCampaignListOutput
        >;
        this.loading = false;
      });
  }

  getCompanies() {
    this.loading = true;
    const { sortBy, sortDesc, page, itemsPerPage }: any = this.options;
    const companyListInput: IPagedListInput | any = {
      filter: this.search,
      pageIndex: page - 1,
      pageSize: itemsPerPage,
    };

    if (sortBy.length > 0 && sortBy[0]) {
      companyListInput.sortBy =
        sortBy + (sortDesc.length > 0 && sortDesc[0] ? " desc" : "");
    }

    const query = "?" + this.queryString.stringify(companyListInput);
    this.nucleusService
      .get<IPagedList<ICompanyListOutput>>("/api/companies" + query, false)
      .then((response) => {
        var companyResponse = response.content as IPagedList<
          ICompanyListOutput
        >;
        this.companies = companyResponse.items;
        this.loading = false;
      });
  }
}
