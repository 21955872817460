import NucleusComponentBase from "@/shared/application/nucleus-component-base";
import { Component, Watch } from "vue-property-decorator";
import Guid from "@/shared/helpers/guid-helper";
import { stringify } from "query-string";
import Swal from "sweetalert2";
import { VueEditor } from "vue2-editor";

@Component({
  components: {
    VueEditor,
  },
})
export default class CompanyUserListComponent extends NucleusComponentBase {
  refs = this.$refs as any;
  allPermissions: IPermissionDto[] = [];
  errors: INameValueDto[] = [];
  loading = true;
  dialog = false;
  formTitle = "";
  options = {};
  search = "";
  selectAll = false;

  get headers() {
    return [
      { text: this.$t("UserName"), value: "userName" },
      { text: "Marka Adı", value: "companyName" },
      { text: this.$t("Durum"), value: "status" },
      { text: this.$t("Actions"), value: "action", sortable: false },
    ];
  }
  get status() {
    return [
      { text: "Onay Bekliyor", value: "0" },
      { text: "Onaylandı", value: "1" },
      { text: "Onaylanmadı", value: "2" },
    ];
  }

  createOrUpdateCompanyUserInput = {
    companyUser: {} as ICompanyUserDto,
  } as ICreateOrUpdateCompanyUserInput;

  pagedListOfCompanyUserListDto: IPagedList<ICompanyUserListOutput> = {
    totalCount: 0,
    items: [],
  };

  @Watch("options")
  onPaginationChanged() {
    this.getCompanyUsers();
  }

  @Watch("search")
  onSearchChanged() {
    this.getCompanyUsers();
  }

  mounted() {
    this.getCompanyUsers();
  }

  editCompanyUser(id: string) {
    this.formTitle = id
      ? this.$t("EditCompanyUser").toString()
      : this.$t("NewCompanyUser").toString();
    this.errors = [];
    this.nucleusService
      .get<IGetCompanyUserForCreateOrUpdateOutput>(
        "/api/companies/" + (id ? id : Guid.empty)
      )
      .then((response) => {
        const result = response.content as IGetCompanyUserForCreateOrUpdateOutput;
        this.createOrUpdateCompanyUserInput = {
          companyUser: result.companyUser,
        };
        this.dialog = true;
      });
  }

  deleteCompanyUser(id: string) {
    this.swalConfirm(this.$t("AreYouSureToDelete").toString()).then(
      (result) => {
        if (result.value) {
          this.nucleusService
            .delete("/api/companies?id=" + id)
            .then((response) => {
              if (!response.isError) {
                this.swalToast(
                  2000,
                  "success",
                  this.$t("Successful").toString()
                );
                this.getCompanyUsers();
              } else {
                var errorText = "";
                response.errors.forEach((error) => {
                  errorText += this.$t(error.name) + "<br>";
                });

                this.swalAlert("error", errorText);
              }
            });
        }
      }
    );
  }

  save() {
    if (this.refs.form.validate()) {
      this.errors = [];
      this.nucleusService
        .postOrPut<void>(
          "/api/companies",
          this
            .createOrUpdateCompanyUserInput as ICreateOrUpdateCompanyUserInput,
          this.createOrUpdateCompanyUserInput.companyUser.id
        )
        .then((response) => {
          if (!response.isError) {
            this.swalToast(2000, "success", this.$t("Successful").toString());
            this.dialog = false;
            this.getCompanyUsers();
          } else {
            this.errors = response.errors;
          }
        });
    }
  }

  getCompanyUsers() {
    this.loading = true;
    const { sortBy, sortDesc, page, itemsPerPage }: any = this.options;
    const companyListInput: IPagedListInput | any = {
      filter: this.search,
      pageIndex: page - 1,
      pageSize: itemsPerPage,
    };

    if (sortBy.length > 0 && sortBy[0]) {
      companyListInput.sortBy =
        sortBy + (sortDesc.length > 0 && sortDesc[0] ? " desc" : "");
    }

    const query = "?" + this.queryString.stringify(companyListInput);
    this.nucleusService
      .get<IPagedList<ICompanyUserListOutput>>(
        "/api/companyuser" + query,
        false
      )
      .then((response) => {
        this.pagedListOfCompanyUserListDto = response.content as IPagedList<
          ICompanyUserListOutput
        >;
        this.loading = false;
      });
  }

  approveRequest(item: any) {
    this.nucleusService
      .postOrPut<void>("/api/companyuser/UpdatePermission", item, "")
      .then((response) => {
        if (!response.isError) {
          this.swalToast(2000, "success", this.$t("Successful").toString());
          this.dialog = false;
          this.getCompanyUsers();
        } else {
          this.errors = response.errors;
        }
      });
  }
  rejectRequestWithDescription(item: any) {
    Swal.fire({
      title: "Aciklama giriniz",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Reddet",
      showLoaderOnConfirm: true,
      preConfirm: (input) => {
        if (input == "") {
          alert("Lütfen Aciklama Giriniz");
          return;
        }
        item.description = input;
        this.nucleusService
          .postOrPut<void>(
            "/api/companyuser/UpdatePermissionWithDecription",
            item,
            ""
          )
          .then((response) => {
            if (!response.isError) {
              this.swalToast(2000, "success", this.$t("Successful").toString());
              this.dialog = false;
              this.getCompanyUsers();
            } else {
              this.errors = response.errors;
            }
          });
      },
    });
  }
  openProfileForEdit(userId: any) {
    let routeData = this.$router.resolve({
      path: "/admin/profile",
      query: { id: userId },
    });
    window.open(routeData.href, "_blank");
  }
}
