import NucleusComponentBase from "@/shared/application/nucleus-component-base";
import { Component, Watch } from "vue-property-decorator";
import Guid from "@/shared/helpers/guid-helper";
import { TheMask } from "vue-the-mask";
import Swal from "sweetalert2";
@Component
export default class UserListComponent extends NucleusComponentBase {
  refs = this.$refs as any;
  loading = true;
  options = {};
  search = "";
  dialog = false;
  formTitle = "";
  errors: INameValueDto[] = [];
  allRoles: IRoleDto[] = [];
  isEdit = false;
  selectAll = false;

  createOrUpdateUserInput = {
    user: {} as IUserDto,
  } as ICreateOrUpdateUserInput;

  pagedListOfUserListDto: IPagedList<IPagedListInput> = {
    totalCount: 0,
    items: [],
  };

  mounted() {
    this.getProfileDetail(this.$route.query.id);
  }

  getProfileDetail(userId: any) {
    if (userId == undefined) {
      userId = this.authStore.getTokenData()[
        "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
      ];
    }

    this.errors = [];
    this.nucleusService
      .get<IGetUserForCreateOrUpdateOutput>("/api/profile?id=" + userId)
      .then((response) => {
        const result = response.content as IGetUserForCreateOrUpdateOutput;
        this.allRoles = result.allRoles;
        this.createOrUpdateUserInput = {
          grantedRoleIds: result.grantedRoleIds,
          user: result.user,
        };

        if (this.createOrUpdateUserInput.user.ibanNumber == null) {
          this.createOrUpdateUserInput.user.ibanNumber = "TR";
        }

        this.formTitle = result.user.userName;
        this.dialog = true;
      });
  }

  save() {
    if (this.createOrUpdateUserInput.user.legislationIbanNumber.length > 0) {
      if (
        !(this.createOrUpdateUserInput.user.legislationIbanNumber.length >= 32)
      ) {
        this.swalAlert(
          "error",
          "Lütfen Iban numarasını eksiksiz olarak giriniz"
        );
        return;
      }
    }
    if (
      this.createOrUpdateUserInput.user.legislationIdentityNumber.length > 0
    ) {
      if (
        !(
          this.createOrUpdateUserInput.user.legislationIdentityNumber.length >=
          11
        )
      ) {
        this.swalAlert(
          "error",
          "Lütfen Tc Kimlik numarasını eksiksiz olarak giriniz"
        );
        return;
      }
    }

    if (this.refs.form.validate()) {
      this.errors = [];
      this.nucleusService
        .postOrPut<void>(
          "/api/profile",
          this.createOrUpdateUserInput as ICreateOrUpdateUserInput,
          this.createOrUpdateUserInput.user.id
        )
        .then((response) => {
          if (!response.isError) {
            this.swalToastCenter(
              3000,
              "success",
              this.$t("Successful").toString()
            );
            this.dialog = false;
            this.getProfileDetail(this.$route.query.id);
          } else {
            this.errors = response.errors;
            window.scrollTo(0, 0);
          }
        });
    }
  }

  isNumber(evt: any) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (
      (charCode > 31 && (charCode < 48 || charCode > 57)) ||
      charCode === 46
    ) {
      evt.preventDefault();
    } else {
      return true;
    }
  }

  openFileUploadDialog() {
    var uploaderInput = this.$refs.uploaderLegislation as HTMLElement;
    uploaderInput.click();
  }
  onFileChanged(e: any) {
    var files = e.target.files[0];

    if (files != undefined) {
      this.checkSize(10485760, files);

      this.errors = [];

      var formData = new FormData();
      formData.append("files", files);
      this.nucleusService
        .postExcel<void>("/api/profile/UploadMemberLegislationFile", formData)
        .then((response) => {
          if (!response.isError) {
            this.swalToast(2000, "success", this.$t("Successful").toString());
            this.dialog = false;
            this.getProfileDetail(this.$route.query.id);
          } else {
            this.errors = response.errors;
          }
        });
    }
  }
  checkSize(max_size: any, file: any) {
    debugger;

    if (file.size > max_size) {
      this.swalToastCenter(
        3000,
        "error",
        "Seçtiğiniz dosya en fazla " +
          max_size / 1024 / 1024 +
          "MB olabilir. Lütfen farklı bir dosya seçiniz"
      );
      return false;
    }

    return true;
  }
}
