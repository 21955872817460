import NucleusComponentBase from "@/shared/application/nucleus-component-base";
import { Component, Watch } from "vue-property-decorator";
import Guid from "@/shared/helpers/guid-helper";
import { stringify } from "query-string";
import { debug } from "console";

@Component
export default class CouponListComponent extends NucleusComponentBase {
  refs = this.$refs as any;

  errors: INameValueDto[] = [];
  loading = true;
  dialog = false;
  formTitle = "";
  options = {};
  search = "";
  selectAll = false;
  files = [];
  companies = [] as ICompanyListOutput[];
  selectedCompanyFilter = "00000000-0000-0000-0000-000000000000";
  get headers() {
    return [
      { text: this.$t("Kampanya Adı"), value: "name" },
      { text: this.$t("Fantaslink UserName"), value: "userName" },
      { text: this.$t("Kampanya Kodu"), value: "code" },
      { text: "Marka Adı", value: "companyName" },
    ];
  }

  createOrUpdateCouponInput = {
    coupon: {} as ICouponDto,
  } as ICreateOrUpdateCouponInput;

  pagedListOfCouponListDto: IPagedList<ICouponListOutput> = {
    totalCount: 0,
    items: [],
  };

  @Watch("options")
  onPaginationChanged() {
    this.getCoupons();
  }

  @Watch("search")
  onSearchChanged() {
    this.getCoupons();
  }
  @Watch("selectedCompanyFilter")
  onCompanyIdChanged() {
    this.getCoupons();
  }

  mounted() {
    this.getCoupons();
  }

  editCoupon(id: string) {
    this.formTitle = id
      ? this.$t("EditCoupon").toString()
      : this.$t("NewCoupon").toString();
    this.errors = [];
    this.nucleusService
      .get<IGetCouponForCreateOrUpdateOutput>(
        "/api/Coupons/" + (id ? id : Guid.empty)
      )
      .then((response) => {
        const result = response.content as IGetCouponForCreateOrUpdateOutput;
        this.createOrUpdateCouponInput = {
          coupon: result.coupon,
        };
        this.dialog = true;
      });
  }

  deleteCoupon(id: string) {
    this.swalConfirm(this.$t("AreYouSureToDelete").toString()).then(
      (result) => {
        if (result.value) {
          this.nucleusService
            .delete("/api/Coupon?id=" + id)
            .then((response) => {
              if (!response.isError) {
                this.swalToast(
                  2000,
                  "success",
                  this.$t("Successful").toString()
                );
                this.getCoupons();
              } else {
                var errorText = "";
                response.errors.forEach((error) => {
                  errorText += this.$t(error.name) + "<br>";
                });

                this.swalAlert("error", errorText);
              }
            });
        }
      }
    );
  }

  save() {
    if (this.refs.form.validate()) {
      this.errors = [];
      this.nucleusService
        .postOrPut<void>(
          "/api/Coupons",
          this.createOrUpdateCouponInput as ICreateOrUpdateCouponInput,
          this.createOrUpdateCouponInput.coupon.id
        )
        .then((response) => {
          if (!response.isError) {
            this.swalToast(2000, "success", this.$t("Successful").toString());
            this.dialog = false;
            this.getCoupons();
          } else {
            this.errors = response.errors;
          }
        });
    }
  }

  getCoupons() {
    this.getCompanies();
    this.loading = true;
    const { sortBy, sortDesc, page, itemsPerPage }: any = this.options;
    const couponListInput: IPagedListInput | any = {
      filter: this.search,
      pageIndex: page - 1,
      pageSize: itemsPerPage,
      companyId: this.selectedCompanyFilter,
    };

    if (sortBy.length > 0 && sortBy[0]) {
      couponListInput.sortBy =
        sortBy + (sortDesc.length > 0 && sortDesc[0] ? " desc" : "");
    }

    const query = "?" + this.queryString.stringify(couponListInput);
    debugger;
    this.nucleusService
      .get<IPagedList<ICouponListOutput>>("/api/Coupons" + query, false)
      .then((response) => {
        debugger;
        this.pagedListOfCouponListDto = response.content as IPagedList<
          ICouponListOutput
        >;
        this.loading = false;
      });
  }
  openFileUploadDialog() {
    var uploaderInput = this.$refs.uploader as HTMLElement;
    uploaderInput.click();
  }
  onFileChanged(e: any) {
    debugger;
    var files = e.target.files[0];
    if (this.files != undefined) {
      this.errors = [];

      var formData = new FormData();
      formData.append("files", files);
      this.nucleusService
        .postExcel<void>("/api/Coupons/UploadWithExcel", formData)
        .then((response) => {
          if (!response.isError) {
            this.swalToast(2000, "success", this.$t("Successful").toString());
            this.dialog = false;
            this.getCoupons();
          } else {
            this.errors = response.errors;
          }
        });
    }
  }

  getCompanies() {
    this.loading = true;
    const { sortBy, sortDesc, page, itemsPerPage }: any = this.options;
    const companyListInput: IPagedListInput | any = {
      filter: this.search,
      pageIndex: page - 1,
      pageSize: itemsPerPage,
    };

    if (sortBy.length > 0 && sortBy[0]) {
      companyListInput.sortBy =
        sortBy + (sortDesc.length > 0 && sortDesc[0] ? " desc" : "");
    }

    const query = "?" + this.queryString.stringify(companyListInput);
    this.nucleusService
      .get<IPagedList<ICompanyListOutput>>("/api/companies" + query, false)
      .then((response) => {
        var companyResponse = response.content as IPagedList<
          ICompanyListOutput
        >;
        this.companies = companyResponse.items;
        this.loading = false;
      });
  }
}
