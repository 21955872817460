import { Component } from "vue-property-decorator";
import NucleusComponentBase from "@/shared/application/nucleus-component-base";

@Component
export default class ConfirmEmailComponent extends NucleusComponentBase {
  refs = this.$refs as any;
  confirmEmailInput = {} as IConfirmEmailInput;
  errors: INameValueDto[] = [];
  isConfirmEmail = false;
  resultMessage: string | undefined;

  mounted() {
    this.saveConfirm();
  }
  saveConfirm() {
    this.confirmEmailInput.token = this.$route.query.token.toString();
    this.confirmEmailInput.userNameOrEmail = this.$route.query.email.toString();
    this.nucleusService
      .post<IResetPasswordOutput>("/api/ConfirmEmail", this.confirmEmailInput)
      .then((response) => {
        if (!response.isError) {
          this.resultMessage = this.$t("ConfirmEmailSuccess").toString();
          this.isConfirmEmail = true;
        } else {
          this.errors = response.errors;
        }
      });
  }
}
