import NucleusComponentBase from "@/shared/application/nucleus-component-base";
import { Component, Watch } from "vue-property-decorator";
import Guid from "@/shared/helpers/guid-helper";
import { stringify } from "query-string";
import LinkPrevue from "link-prevue";

@Component({ components: { LinkPrevue } })
export default class CollectionComponent extends NucleusComponentBase {
  refs = this.$refs as any;
  allPermissions: IPermissionDto[] = [];
  errors: INameValueDto[] = [];
  loading = true;
  dialog = false;
  formTitle = "";
  options = {};
  search = "";
  selectAll = false;

  collectionUserName = "";
  collectionName = "";

  get headers() {
    return [
      { text: this.$t("CollectionName"), value: "name" },
      { text: this.$t("Actions"), value: "action", sortable: false },
    ];
  }
  get headersCollectionItem() {
    return [
      { text: this.$t("CollectionName"), value: "url" },
      { text: this.$t("Actions"), value: "action", sortable: false },
    ];
  }

  createOrUpdateCollectionInput = {
    collectionItems: [],
    collection: {} as ICollectionDto,
  } as ICreateOrUpdateCollectionInput;

  pagedListOfCollectionListDto: IPagedList<ICollectionListOutput> = {
    totalCount: 0,
    items: [],
  };

  collectionItemUrl = "";

  mounted() {
    this.collectionUserName = window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 2
    ];
    this.collectionName = window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];
    this.editCollection();
  }

  editCollection() {
    debugger;
    this.errors = [];
    this.nucleusService
      .get<IGetCollectionForCreateOrUpdateOutput>(
        "/api/collections/getCollection/" +
          this.collectionUserName +
          "/" +
          this.collectionName
      )
      .then((response) => {
        const result = response.content as IGetCollectionForCreateOrUpdateOutput;
        this.createOrUpdateCollectionInput = {
          collection: result.collection,
          collectionItems: result.collectionItems,
        };
      });
  }

  addCollectionItem() {
    var collectionItem = { url: this.collectionItemUrl } ;
    this.createOrUpdateCollectionInput.collectionItems.push(collectionItem);
  }
}
