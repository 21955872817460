import NucleusComponentBase from "@/shared/application/nucleus-component-base";
import { Component, Watch } from "vue-property-decorator";
import Guid from "@/shared/helpers/guid-helper";
import { stringify } from "query-string";
import { debug } from "console";

@Component
export default class CampaignListComponent extends NucleusComponentBase {
  refs = this.$refs as any;
 
  errors: INameValueDto[] = [];
  loading = true;
  dialog = false;
  formTitle = "";
  options = {};
  search = "";
  selectAll = false;
  files = [];

  get headers() {
    return [
      { text: this.$t("Kampanya Adı"), value: "name" },
      { text: "En çok link içi ciro getiren kategoriler", value: "mostCategoryCiroWithLink" },
      { text: "En çok link dışı ciro getiren kategoriler", value: "mostCategoryCiroWithoutLink" },
      { text: "En çok sattırdığı ürünler", value: "mostSoldProduct" }
     
    ];
  }

  createOrUpdateCampaignInput = {
    campaign: {} as ICampaignDto,
  } as ICreateOrUpdateCampaignInput;

  pagedListOfCampaignListDto: IPagedList<
    ICampaignListOutput
  > = {
    totalCount: 0,
    items: [],
  };

  @Watch("options")
  onPaginationChanged() {
    this.getCampaigns();
  }

  @Watch("search")
  onSearchChanged() {
    this.getCampaigns();
  }

  mounted() {
    this.getCampaigns();
  }

  editCampaign(id: string) {
    this.formTitle = id
      ? this.$t("EditCampaign").toString()
      : this.$t("NewCampaign").toString();
    this.errors = [];
    this.nucleusService
      .get<IGetCampaignForCreateOrUpdateOutput>(
        "/api/Campaigns/" + (id ? id : Guid.empty)
      )
      .then((response) => {
        const result = response.content as IGetCampaignForCreateOrUpdateOutput;
        this.createOrUpdateCampaignInput = {
          campaign: result.campaign,
        };
        this.dialog = true;
      });
  }

  deleteCampaign(id: string) {
    this.swalConfirm(this.$t("AreYouSureToDelete").toString()).then(
      (result) => {
        if (result.value) {
          this.nucleusService
            .delete("/api/Campaign?id=" + id)
            .then((response) => {
              if (!response.isError) {
                this.swalToast(
                  2000,
                  "success",
                  this.$t("Successful").toString()
                );
                this.getCampaigns();
              } else {
                var errorText = "";
                response.errors.forEach((error) => {
                  errorText += this.$t(error.name) + "<br>";
                });

                this.swalAlert("error", errorText);
              }
            });
        }
      }
    );
  }

  save() {
    if (this.refs.form.validate()) {
      this.errors = [];
      this.nucleusService
        .postOrPut<void>(
          "/api/Campaign",
          this
            .createOrUpdateCampaignInput as ICreateOrUpdateCampaignInput,
          this.createOrUpdateCampaignInput.campaign.id
        )
        .then((response) => {
          if (!response.isError) {
            this.swalToast(2000, "success", this.$t("Successful").toString());
            this.dialog = false;
            this.getCampaigns();
          } else {
            this.errors = response.errors;
          }
        });
    }
  }

  getCampaigns() {
    this.loading = true;
    const { sortBy, sortDesc, page, itemsPerPage }: any = this.options;
    const campaignListInput: IPagedListInput | any = {
      filter: this.search,
      pageIndex: page - 1,
      pageSize: itemsPerPage,
    };

    if (sortBy.length > 0 && sortBy[0]) {
      campaignListInput.sortBy =
        sortBy + (sortDesc.length > 0 && sortDesc[0] ? " desc" : "");
    }

    const query = "?" + this.queryString.stringify(campaignListInput);
    debugger;
    this.nucleusService
      .get<IPagedList<ICampaignListOutput>>(
        "/api/Campaigns" + query,
        false
      )
      .then((response) => {
        debugger;
        this.pagedListOfCampaignListDto = response.content as IPagedList<
          ICampaignListOutput
        >;
        this.loading = false;
      });
  }
  openFileUploadDialog() {
    var uploaderInput = this.$refs.uploader as HTMLElement;
    uploaderInput.click();
  }
  onFileChanged(e: any) {
    debugger;
    var files = e.target.files[0];
    if (this.files != undefined) {
      this.errors = [];

      var formData = new FormData();
      formData.append("files", files);
      this.nucleusService
        .postExcel<void>("/api/Campaigns/UploadWithExcel", formData)
        .then((response) => {
          if (!response.isError) {
            this.swalToast(2000, "success", this.$t("Successful").toString());
            this.dialog = false;
            this.getCampaigns();
          } else {
            this.errors = response.errors;
          }
        });
    }
  }
}
